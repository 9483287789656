import React from 'react';

function Post() {

  return (
    <div>
      <p>Hello Nice World</p>
    </div>
  )
}

export default Post;