import './css/post.css'
import React, { useEffect, useState } from "react";
import swal from 'sweetalert';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import CustomNavbar from "./navbar";

function Posts() {

  const [posts, setPosts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({ username: '', title: '', content: ''});

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch('https://my-app.rexterds.workers.dev/posts', {
        method: 'GET',
        headers: { 
          'Content-type': 'application/json'
        }
      });

      const data = await response.json()

      setPosts(data);
    }

    getPosts();
  }, []);

  const openForm = () => setShowForm(true);
  const closeForm = () => setShowForm(false);

  const updateFormValues = (e) => {
    const { id, value } = e.target;

    setFormValues({
      ...formValues,
      [id]: value
    })
  }

  const addPost = async () => {

    const { username, title, content } = formValues;

    if (username === '' && title === '' && content === '') {
      return swal('Error', 'Please fill out the required items', 'error');
    }

    const newId = `${username}:${uuidv4()}`;
    const newData = JSON.stringify({ "username": username, "title": title, "content": content });

    const response = await fetch('https://my-app.rexterds.workers.dev/posts', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ "id": newId, "data": newData })
    })

    const { status } = response;

    closeForm();
    if (status === 200) {
      return swal('Success', 'Successfully added post!', 'success');
    }

    return swal('Error', 'Something went wrong! Try again!', 'error');
  }

  return (
    <div>
      <CustomNavbar/>
      <div style={{ 'backgroundColor': '#edeee9', 'minHeight': '100vh'}}>
        <div class='pt-3 post' >
          <Navbar expand='lg'>
            <Navbar.Brand><h1>CloudPosts</h1></Navbar.Brand>
            <Navbar.Collapse className='justify-content-end'>
              <Button class='btn' style={{ backgroundColor: '#2a9ec5'}} onClick={openForm}>Add new</Button>
            </Navbar.Collapse>
          </Navbar>
          <Modal show={showForm} onHide={closeForm}>
            <Modal.Header closeButton>
              <Modal.Title>Add new post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId='username'>
                  <Form.Label>Username *</Form.Label>
                  <Form.Control type='username' placeholder='Enter username' onChange={updateFormValues}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId='title'>
                  <Form.Label>Title *</Form.Label>
                  <Form.Control type='text' placeholder='Enter title' onChange={updateFormValues}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId='content'>
                  <Form.Label>Content *</Form.Label>
                  <Form.Control as='textarea' rows={4} placeholder="What's your story?"  onChange={updateFormValues}/>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeForm}>
                Close
              </Button>
              <Button variant="primary" onClick={addPost}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          {
            posts.map(post => {
              const data = JSON.parse(post.data);
              
              return (
                <div class='card mb-3'>
                  <div class='card-body'>
                    <h5 class='card-title'>{data.title}</h5>
                    <h6 class='card-subtitle mb-3 text-muted'>{data.username}</h6>
                    <p class='card-text'>{data.content}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Posts;