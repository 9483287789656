import React from 'react';

export default function CustomNavbar() {

  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src={'/images/cloudflare_icon.png'}  alt="logo" width="30" height="30"/>
          CloudBook
        </a>
      </div>
    </nav>
  );
}