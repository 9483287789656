import './App.css';
import { Router } from '@reach/router';

import Landing from './components/landing'
import Posts from './components/posts';
import Post from './components/post';

function App() {
  return (
    <Router>
      <Landing path='/'/>
      <Posts path='/posts'/>
      <Post path='/posts/:id'/>
    </Router>
  );
}

export default App;
