import React from 'react';
import CustomNavbar from './navbar';

function Landing() {

  return (
    <div>
      <CustomNavbar/>
      <div class='card'>
        <img class='card-img-fluid' src='/images/clouds.jpg' alt="cloud background" />
        <div class='card-img-overlay text-center container d-flex flex-column align-items-center justify-content-center'>
            <h1 class="display-1" style={{ letterSpacing: '0.1em'}}>CLOUDBOOK</h1>
            <a href='/posts'>
              <button class='btn' style={{ backgroundColor: '#2a9ec5', marginTop: '1rem'}}>See Posts</button>
            </a>
        </div>
      </div>
    </div>
  )
}

export default Landing;